// export const URL = "http://localhost:8080/ie/"; // for production
// export const URLTEST = "http://localhost:8080/ie/"; // for server test
// export const urlLocal = "http://localhost:8080/ie/"; //for local test

// export const URL = "http://taobao-express-sarlu.com/ie/importation-express-api/"; // for production
// export const URLTEST = "http://taobao-express-sarlu.com/ie/importation-express-api/"; // for server test
// export const urlLocal = "http://taobao-express-sarlu.com/ie/importation-express-api/"; //for local test

export const URL = "https://importation-express.com/ie/"; // for production
export const URLTEST = "https://importation-express.com/ie/"; // for server test
export const urlLocal = "https://importation-express.com/ie/"; //for local test
